<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center">
          <h2 class="text-2xl font-weight-semibold">
            Login
          </h2>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Please sign-in to your account
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            method="post"
            action="/"
            lazy-validation
            @submit.prevent="login()"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-text-field
                  v-model="form.phone_number"
                  outlined
                  label="Username or Phone Number"
                  placeholder="07XXXXXXXX"
                  :rules="phoneRules"
                  autocomplete="phone_number"
                ></v-text-field>
                <small
                  v-show="form.errors.has('phone_number')"
                  class="validation-error"
                >{{ form.errors.get('phone_number') }}</small>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.password"
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :rules="passwordRules"
                  autocomplete="current-password"
                  required
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
                <small
                  v-show="form.errors.has('password')"
                  class="validation-error"
                >{{
                  form.errors.get('password')
                }}</small>
              </v-col>
            </v-row>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <router-link :to="{ name: 'hotspot-forgot-password', query: queryParams }">
                Forgot Password?
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="form.busy"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-text
          class="d-flex align-center justify-center flex-wrap mt-2"
        >
          <span class="me-2">
            Don't have an account?
          </span>
          <router-link
            v-if="mustRegister"
            :to="{ name:'hotspot-register', query: queryParams }"
          >
            Create one
          </router-link>
          <router-link
            v-else
            ref="hotspotInternetPlansLink"
            :to="{ name:'hotspot-internet-plans', query: queryParams }"
          >
            Buy an internet plan
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      alt="background-img"
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mapActions } from 'vuex'
import validationRules from '@/mixins/validationRules'
import routerLoginMixin from '@/mixins/routerLoginMixin'
import routerQueryParamMixin from '@/mixins/routerQueryParamMixin'

export default {
  mixins: [validationRules, routerLoginMixin, routerQueryParamMixin],
  data: () => ({
    passwordRules: [v => !!v || 'Password is required'],
  }),
  setup() {
    const isPasswordVisible = ref(false)

    return {
      isPasswordVisible,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  computed: {
    mustRegister() {
      return process.env.VUE_APP_HOTSPOT_USER_MUST_REGISTER !== 'false'
    },
  },
  methods: {
    ...mapActions(['authenticateUser']),
    login() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.form
        .post('hotspot/login')
        .then(response => {
          const {
            data: {
              token, user, roles, permissions,
            },
          } = response
          axios.defaults.headers.common.Authorization = `Bearer ${token}`

          const userInfo = {
            id: user.customer_id,
            name: user.name,
            username: user.username,
            phone: user.phone_number,
            authenticationToken: token,
            roles,
            permissions,
            routerLoginLink: this.form.link_login_only,
          }
          this.authenticateUser(userInfo)

          if (user.phone_number_verified) {
            if (user.customer_plan_status === 1) {
              this.loginToRouter(user.username, this.form.password, this.form.link_login_only)
            } else {
              this.$router.push({ name: 'hotspot-internet-plans', query: this.queryParams })
              if (!this.mustRegister) {
                this.$toast.info('Please buy an internet plan first')
              }
            }
          } else {
            this.$router.push({ name: 'otp-verification' })
          }
        })
        .catch(error => {
          console.log('🚀 ~ file: Login.vue:174 ~ login ~ error:', error)
          this.$toast.error(error.response.data.message)
        })
    },

  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
